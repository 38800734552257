import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import img1 from "../../../static/img/por-que/1.jpg"
import img2 from "../../../static/img/por-que/2.jpg"
import img3 from "../../../static/img/por-que/3.jpg"
import soluciones from "../../../static/img/por-que/buscamos_soluciones.png";

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="¿Por qué IRStrat?"/>
            <Banner title="¿Por qué IRStrat?"/>

            <section className="pt-5 pb-5 porque">
                <div className="container">
                    <div className="section_title black_c ">
                        <h2 className="mt-4">¿Por qué IRStrat?</h2>
                        <span className="sec_border"/>
                        <p className="f_16">Somos la mejor opción para articular y difundir su tesis de inversión, pues
                            ninguna otra consultoría del ramo conoce como nosotros la cultura y los fundamentales
                            macroeconómicos de México. ¿Cómo se podrían explicar los fundamentales operativos de su
                            compañía,
                            cuando no se conocen las particularidades de su nicho de mercado? ¿Quién mejor para vender a
                            México que los expertos en México? “Trabajamos duro hacia adentro para obtener un potente
                            alcance internacional hacia afuera”.</p>
                    </div>
                </div>
            </section>

            <section className="position-relative">
                <div className="container">
                    <div id="testimonial" className="testimonial_slider owl-carousel testimonial_porque">
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left">IRStrat es la mejor opción para el mercado mexicano
                                                en relaciones con
                                                inversionistas, gracias al profundo conocimiento financiero de su
                                                equipo, su
                                                vasto portafolio de servicios y su amplia red de contactos
                                                institucionales,
                                                tanto en México como en los mercados internacionales.</p>
                                        </div>
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={img1} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left">Nuestra especialización en México nos permite
                                                trabajar eficientemente en poner al
                                                alcance de su mano
                                                la creciente participación de los fondos de pensiones mexicanos en
                                                instrumentos
                                                locales como el
                                                suyo.</p>
                                        </div>
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={img2} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left">Estamos en constante comunicación con el mercado y
                                                las autoridades locales (BMV,
                                                CNBV, CONSAR, etc.),
                                                siempre alertas de alcanzar un máximo cumplimiento regulatorio y de
                                                mejores
                                                prácticas, y a través de
                                                nuestra oficina canadiense mantenemos estrecha cercanía con los
                                                principales
                                                mercados
                                                y autoridades de
                                                Norteamérica (NYSE, TSX, SEC, etc.).</p>
                                        </div>
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                                <img src={img3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-5 pb-5 porque">
                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-sm-12">
                            <div className="about_title text-center">
                                <img className="wow fadeInUp w-100" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                     src={soluciones} alt={" "}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
